import React from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { Component } from 'react'
import { GB, DE } from 'country-flag-icons/react/3x2'
import { FormattedMessage } from 'react-intl'

import { Context } from "./utilities/Locale"

import '../css/NavBars.css'

class NavBottomBar extends Component {
    constructor(props) {
        super(props)
        this.changeLanguage = this.changeLanguage.bind(this)
        this.state = {
            selectedLanguage: navigator.language,
            langSelectorTitle: navigator.language.includes('en') ? (<GB className="flag-icon" />) : (<DE className="flag-icon" />)
        }
    }

    static contextType = Context

    changeLanguage = (to) => {
        try {
          switch(to) {
            case 'de':
              if(this.state.selectedLanguage !== 'de') {
                this.setState({ selectedLanguage: 'de', langSelectorTitle: (<DE className="flag-icon" />)})
                this.context.locale = 'de'
                this.context.selectLanguage('de')
              }
              break
            case 'en':
              if(this.state.selectedLanguage !== 'en') {
                this.setState({selectedLanguage: 'en', langSelectorTitle: (<GB className="flag-icon" />)})
                this.context.locale = 'en'
                this.context.selectLanguage('en')
              }
              break
            default: 
              console.log('Entering default switch of NavSidebar.jsx:changeLanguage().')
          }
        }
        catch(exception) {
          console.log('Error in NavSidebar:changeLanguage(): ' + exception)
        }
      }

    render() {
        return (
            <Navbar className='bottom-nav'>  
                <Nav className='bottom-nav-nav'>
                <Nav.Item>
                    <NavLink to="/privacy" className='bottom-nav-link' onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }) }}><FormattedMessage id="nav.privacy" /></NavLink>
                </Nav.Item>
                <Nav.Item>
                    <NavLink to="/imprint" className='bottom-nav-link'onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }) }}><FormattedMessage id="nav.imprint" /></NavLink>
                </Nav.Item>
                <NavDropdown id='lang-dropdown' drop='up' className='lang-selector' title={this.state.langSelectorTitle}>
                    <NavDropdown.Item onClick={() => this.changeLanguage('en')}>
                    <GB className='flag-icon' />
                    </NavDropdown.Item>
                    <NavDropdown.Item onClick={() => this.changeLanguage('de')}>
                    <DE className='flag-icon' />
                    </NavDropdown.Item>
                </NavDropdown>
                </Nav>
            </Navbar>
        )
    }
}

export default NavBottomBar