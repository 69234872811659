import React from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import { Component } from 'react'
import { FormattedMessage } from 'react-intl'

import ProSkiveLogo from '../resources/media/logos/proskive-logo.png'
import GitlabLogo from '../resources/media/logos/gitlab-logo.png'
import ToolPoolLogo from '../resources/media/logos/tp-logo.png'
import ZulipLogo from '../resources/media/logos/zulip-logo.png'
import InfoIcon from '../resources/media/icons/info-icon.png'
import UseIcon from '../resources/media/icons/use-icon.png'
import JobIcon from '../resources/media/icons/job-icon.png'
import BlogIcon from '../resources/media/icons/blog-icon.png'
import ContactIcon from '../resources/media/icons/contact-icon.png'

import '../css/NavBars.css'

class NavSidebar extends Component {
  constructor(props) {
    super(props)
    this.toggleMenu = this.toggleMenu.bind(this)
    this.state = {
      expanded: false
    }
  }

  toggleMenu = () => {
    if(window.innerWidth <= 768) {
      if(this.state.expanded === false ) {
        document.getElementById('content').style.top = '600px'
        this.setState({expanded: true})
      }
      else {
        document.getElementById('content').style.top = '100px'
        this.setState({expanded: false})
      } 
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  render() {
    return (
      <div className='sidebar'>
        <Navbar.Brand>
            <NavLink to="/"><img src={ProSkiveLogo} width="350" alt="ProSkive Logo" className='brand'/></NavLink>
        </Navbar.Brand>
        <Navbar id='navbar' expanded={this.state.expanded} bg="white" expand="md" className="main-nav-custom">
          <Navbar.Toggle id='toggle' onClick={this.toggleMenu} aria-controls="basic-navbar-nav" className='toggle' />
          <Navbar.Collapse className='collapse' id="basic-navbar-nav">
            <Nav className='flex-column'>
              <NavLink to='/about' className='nav-link-custom' onClick={this.toggleMenu}><img src={InfoIcon} alt='Info Icon' className='navIcon' /><FormattedMessage id="nav.about" /></NavLink>
              <NavLink to='/use' className='nav-link-custom' onClick={this.toggleMenu}><img src={UseIcon} alt='Info Icon' className='navIcon' /><FormattedMessage id="nav.use" /></NavLink>
              <NavLink to='/jobs' className='nav-link-custom' onClick={this.toggleMenu}><img src={JobIcon} alt='Info Icon' className='navIcon' /><FormattedMessage id="nav.jobs" /></NavLink>
              <NavLink to='/blog' className='nav-link-custom' onClick={this.toggleMenu}><img src={BlogIcon} alt='Info Icon' className='navIcon' /><FormattedMessage id="nav.blog" /></NavLink>
              <NavLink to='/contact' className='nav-link-custom' onClick={this.toggleMenu}><img src={ContactIcon} alt='Info Icon' className='navIcon' /><FormattedMessage id="nav.contact" /></NavLink>
              <Nav.Item className='logoNavItems'>
                <a href='https://gitlab.proskive.de/proskive/local' target='_blank' rel="noreferrer">
                  <img src={GitlabLogo} alt="Gitlab Logo" className='gitlabLogo' />
                </a>
                <a href='https://www.toolpool-gesundheitsforschung.de/index.php/produkte/proskive' target='_blank' rel="noreferrer">
                  <img src={ToolPoolLogo} alt="Toolpool Logo" className='toolpoolLogo' />
                </a>
                <a href='https://mii.zulipchat.com' target='_blank' rel="noreferrer">
                  <img src={ZulipLogo} alt="Zulip Logo" className='zulipLogo' />
                </a>
              </Nav.Item>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    )
  }
}

export default NavSidebar