import { Container, Card, Image } from "react-bootstrap"
import { motion } from 'framer-motion'
import { FormattedMessage } from 'react-intl'

//import Screenshot1 from '../../resources/media/screenshots/application-overview-screen.png'
//import Screenshot2 from '../../resources/media/screenshots/create-application-screen.png'
//import Screenshot3 from '../../resources/media/screenshots/coordination-screen.png'
//import Screenshot4 from '../../resources/media/screenshots/dashboard-screen.png'
//import Screenshot5 from '../../resources/media/screenshots/voting-screen.png'
import Process from '../../resources/media/process.png'
import GitlabLogo from '../../resources/media/logos/gitlab-logo.png'

import '../../css/App.css'
import '../../css/content/Use.css'

function Use() {
    return (
        <motion.div
            initial={{opacity: 0}} 
            animate={{opacity: 1}} 
            exit={{opacity: 0}}>
            <Container>
                <h1 className='contentHeadline'><FormattedMessage id="use.headline" /></h1>
                <Card style={{ border: '0'}}>
                    <Card.Body>     
                        <Card.Text><FormattedMessage id="use.description" /></Card.Text>
                    </Card.Body>
                </Card>
                <Image src={Process} width={'100%'} className="processImage" />
                <div className='contentRow'>
                    <Card className="contentCard">
                        <Card.Body>
                            <Card.Title className='contentSubheadline'><FormattedMessage id="use.setupHeadline" /></Card.Title>
                            <Card.Text><FormattedMessage id="use.setupDescription" values={{gitlabLink: <a href="https://gitlab.proskive.de/proskive/local" target="_blank" rel="noreferrer">Gitlab Repository</a>, documentationLink: <a href="https://gitlab.proskive.de/proskive/local/releases/09-2023/proskive-09-2023-docker-install/-/blob/main/Installationsanleitung_Release_09-2023.pdf" target="_blank" rel="noreferrer">Gitlab Repository</a>}} /></Card.Text>
                        </Card.Body>
                    </Card>
                    <Card className="contentCard">
                        <Card.Body>
                            <Card.Title className='contentSubheadline'><FormattedMessage id="use.contributeHeadline" /></Card.Title>
                            <Card.Text><FormattedMessage id="use.contributeDescription" values={{gitlabLink: <a href="https://gitlab.proskive.de/proskive/local" target="_blank" rel="noreferrer">Gitlab Repository</a>, documentationLink: <a href="https://gitlab.proskive.de/proskive/local/releases/09-2023/proskive-09-2023-docker-install/-/blob/main/Installationsanleitung_Release_09-2023.pdf" target="_blank" rel="noreferrer">Gitlab Repository</a>}} /></Card.Text>
                        </Card.Body>
                    </Card>
                    <a href="https://gitlab.proskive.de/proskive/local" target="_blank" rel="noreferrer"><Image src={GitlabLogo} className="gitlabLogoLarge" /></a>
                </div>
                <Card className="contentCard">
                    <Card.Body>
                        <Card.Title className='contentSubheadline'><FormattedMessage id="use.demoHeadline" /></Card.Title>
                        <Card.Text><FormattedMessage id="use.demoDescription" /></Card.Text>
                    </Card.Body>
                </Card>
                <Card className="contentCard">
                    <Card.Body>
                        <Card.Title className='contentSubheadline'><FormattedMessage id="use.supportHeadline" /></Card.Title>
                        <Card.Text><FormattedMessage id="use.supportDescription" values={{p: (...chunks) => <p>{chunks}</p>, zulipChannel: <a href="https://mii.zulipchat.com" target="_blank" rel="noreferrer">MII Zulip #ProSkive Channel</a>, telkoLink: <a href="https://zoom.us/j/499576463?pwd=emJKM2g1N2N3NjNCR3lmMzFFZGRZQT09" target="blank" rel="noreferer">Zoom Link</a>}}/></Card.Text>
                    </Card.Body>
                </Card>
            </Container>
        </motion.div>
    )
  }
  
  export default Use